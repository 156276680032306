import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import router from "@/router";
export default defineComponent({
    name: "blockInfo",
    props: {
        hash: String
    },
    setup: function (props) {
        var store = useStore();
        store.dispatch("fetchBlockByHash", props.hash);
        onMounted(function () {
            setCurrentPageTitle("Block's Details");
            setTimeout(function () {
                //
            }, 1000);
            if (props.hash != null) {
                store.dispatch("fetchBlockByHash", props.hash);
            }
            else {
                router.push("500");
            }
        });
    },
    computed: {
        block: function () {
            var store = useStore();
            return store.getters.blockInfo;
        }
    },
    methods: {
        /**
         * Formats number with leading hashtag and zeros
         * @returns string
         */
        pad: function (num, size) {
            //fixed size
            var SIZE = size ? size : 9;
            // num = num.toString();
            num = "" + num;
            while (num.length < SIZE) {
                num = "0" + num;
            }
            return "#" + num;
        },
        /**
         * Converts bytes to kB(level: 1) or MB(level: 2).
         * @returns number
         */
        capitalize: function (str) {
            setTimeout(function () {
                //
            }, 1000);
            return str.toUpperCase();
        },
        /**
         * Converts bytes to kB(level: 1) or MB(level: 2).
         * @returns number
         */
        converBytes: function (num, level) {
            //level size
            var SIZE = 1024;
            // bytes -> kB -> MB
            return (num / Math.pow(SIZE, level)).toFixed(2);
        }
    }
});
