<template>
  <!--begin::Block Card-->
  <div class="card card-flush card-px-0 shadow-sm w-100 bg-dark">
    <!--begin::Card Header-->
    <div class="card-header">
      <h3 class="card-title fs-1 text-muted ps-5">
        Block
        <span class="badge bg-dark fs-1 mb-1 ps-5 ms-5">
          {{ pad(block.id, 9) }}
        </span>
      </h3>
      <!-- <div class="card-toolbar">
        <button
          id="copy-btn"
          type="button"
          class="btn btn-primary btn-light btn-copy js-tooltip js-copy me-5"
          data-toggle="tooltip"
          data-placement="bottom"
          data-clipboard-target=""
          data-copy="{ block }"
          title="Copy to clipboard"
        >
          Copy to Clipboard
        </button>
      </div> -->
      <!--end::Card Header-->
    </div>
    <!--begin::Checksum-->
    <div class="text-muted fs-3 ps-5">{{ block.checksum }}</div>
    <!--end::Checksum-->
    <!--start::Separator-->
    <div class="menu-item">
      <div class="menu-content">
        <div class="separator mx-1 mt-4"></div>
      </div>
    </div>
    <!--end::Separator-->
    <!--begin::Card Body-->
    <div class="card-body pb-5">
      <!--begin::Block info-->
      <div class="card-body d-flex flex-column p-1 pt-1 mb-9">
        <!--begin::Summary block-->
        <div class="mb-5 text-inverse-info badge-dark text-center">
          <h3 class="fs-1 pb-5 fw-bolder mx-5 text-white">Summary</h3>
        </div>
        <!--end::Summary block-->
        <!--begin::Status block-->
        <div class="row mb-7">
          <label class="col-lg-4 text-gray-700">Block status</label>
          <div class="col-lg-8">
            <!--begin::In queue blocks-->
            <span v-if="block.file_hash == null" class="badge badge-warning">
              In queue
            </span>
            <!--end::In queue blocks-->
            <!--begin::Registered blocks-->
            <span class="badge badge-success text-dark" v-else>
              Registered
            </span>
            <!--end::Registered blocks-->
          </div>
        </div>
        <!--end::Status block-->
        <!--begin::Protocol Number block-->
        <div class="row mb-7">
          <label class="col-lg-4 fw-bold text-gray-700">Protocol Number</label>
          <div class="col-lg-8 fv-row">
            <span class="fw-bold text-white fs-6">
              {{ block.pn ? pad(block.pn, 10) : "" }}
            </span>
          </div>
        </div>
        <!--end::Protocol Number block-->
        <!--begin::Type block-->
        <div class="row mb-7">
          <label class="col-lg-4 fw-bold text-gray-700">Block type</label>
          <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-white">
              {{
                block.type
                  ? this.capitalize(block.type)
                  : "File type check pending"
              }}
            </span>
          </div>
        </div>
        <!--end::Type block-->
        <div class="row mb-7">
          <!--begin::Size block-->
          <label class="col-lg-4 fw-bold text-gray-700">Block size</label>
          <div class="col-lg-8">
            <span class="fw-bold fs-6 text-white">
              {{ block.blocksize ? converBytes(block.blocksize, 2) : "--" }} MB
            </span>
          </div>
        </div>
        <!--end::Size block-->
        <!--begin::Creation block-->
        <div class="row mb-10">
          <label class="col-lg-4 fw-bold text-gray-700"
            >Creation block Timestamp</label
          >
          <div class="col-lg-8">
            <span class="fw-bold text-white fs-6">
              {{ block.created_at ? block.created_at : "" }}
              <i
                class="fas fa-exclamation-circle ms-1 fs-7"
                data-bs-toggle="tooltip"
                title="Timezone UTC+00"
                data-bs-original-title="Timezone UTC+00"
                aria-label=""
              ></i>
            </span>
          </div>
        </div>
        <!--end::Creation block-->
        <!--begin::Chainlink info block-->
        <div
          class="notice d-flex bg-dark-info text-muted rounded border-light border border-dashed p-6"
          v-if="block.file_hash"
        >
          <div class="d-flex flex-stack flex-grow-1">
            <!--begin::Content-->
            <div class="fw-bold">
              <h4 class="text-gray-900 fw-bolder mb-4">Chainlink Details</h4>
              <!--begin::Chainlink ID block-->
              <div class="row mb-2">
                <span class="fs-6 text-gray-700 mb-2 col-lg-4"
                  >Chainlink ID</span
                ><span class="fs-6 text-black-700 col-lg-8"
                  >cw-chain-link-{{ block.cid }}.cwd</span
                >
              </div>
              <!--end::Chainlink ID block-->
              <!--begin::Digital Signature block-->
              <div class="row mb-2">
                <span class="fs-6 text-gray-700 mb-2 col-lg-4">
                  Digital Signature
                </span>
                <span class="fs-6 text-black-700 col-lg-8 d-flex">
                  {{ block.file_hash }}
                </span>
              </div>
              <!--end::Digital Signature block-->
              <!--begin::Chainlink size block-->
              <div class="row mb-2">
                <span class="fs-6 text-gray-700 mb-2 col-lg-4">
                  Chainlink size
                </span>
                <span class="fs-6 text-black-700 col-lg-8">
                  {{ converBytes(block.chain_filesize, 1) }}B
                </span>
              </div>
              <!--end::Chainlink size block-->
              <!--begin::Sync Timestamp block-->
              <div class="row mb-2">
                <span class="fs-6 text-gray-700 mb-2 col-lg-4"
                  >Sync Timestamp</span
                >
                <span class="fs-6 text-black-700 col-lg-8">{{
                  block.chain_timestamp
                }}</span>
              </div>
              <!--end::Sync Timestamp block-->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Wrapper-->
        </div>
        <div class="alert alert-primary d-flex flex-column" v-else>
          <h4 class="mb-1 text-dark">Info</h4>
          <span>Sync pending</span>
        </div>
        <!-- <div class="alert alert-primary d-flex bg-secondary rounded border-info border border-dashed p-6" v-else>
          <div class="badge badge-info ">
            Sync pending.
          </div>
        </div> -->
        <!--end::Chainlink info block-->
      </div>
      <!--end::Block info-->
      <router-link
        :to="{
          name: 'blocks'
        }"
        class="fw-bold fs-1 ms-10 "
      >
        &laquo; Back
      </router-link>
    </div>
    <!--end::Card Body-->
  </div>
  <!--end::Block Card-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import router from "@/router";

export default defineComponent({
  name: "blockInfo",
  props: {
    hash: String
  },
  setup(props) {
    const store = useStore();
    store.dispatch("fetchBlockByHash", props.hash);

    onMounted(() => {
      setCurrentPageTitle("Block's Details");

      setTimeout(() => {
        //
      }, 1000);

      if (props.hash != null) {
        store.dispatch("fetchBlockByHash", props.hash);
      } else {
        router.push("500");
      }
    });
  },
  computed: {
    block() {
      const store = useStore();
      return store.getters.blockInfo;
    }
  },
  methods: {
    /**
     * Formats number with leading hashtag and zeros
     * @returns string
     */
    pad(num, size?) {
      //fixed size
      const SIZE = size ? size : 9;
      // num = num.toString();
      num = `${num}`;

      while (num.length < SIZE) {
        num = "0" + num;
      }

      return "#" + num;
    },

    /**
     * Converts bytes to kB(level: 1) or MB(level: 2).
     * @returns number
     */
    capitalize(str: string) {
      setTimeout(() => {
        //
      }, 1000);
      return str.toUpperCase();
    },

    /**
     * Converts bytes to kB(level: 1) or MB(level: 2).
     * @returns number
     */
    converBytes(num: number, level: number) {
      //level size
      const SIZE = 1024;
      // bytes -> kB -> MB
      return (num / SIZE ** level).toFixed(2);
    }
  }
});
</script>
